exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-asiaandamerica-js": () => import("./../../../src/pages/asiaandamerica.js" /* webpackChunkName: "component---src-pages-asiaandamerica-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quickrtw-js": () => import("./../../../src/pages/quickrtw.js" /* webpackChunkName: "component---src-pages-quickrtw-js" */),
  "component---src-pages-worldbike-js": () => import("./../../../src/pages/worldbike.js" /* webpackChunkName: "component---src-pages-worldbike-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/postPage.js" /* webpackChunkName: "component---src-templates-post-page-js" */),
  "component---src-templates-year-page-js": () => import("./../../../src/templates/yearPage.js" /* webpackChunkName: "component---src-templates-year-page-js" */)
}

